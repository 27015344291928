<template>
  <div class="maxcontainer container">
    <PageLoader v-if="loading"/>
    <div >
      <SmartFeedConsolidatedData v-if="userLevel !== 'employee' && showConsolidatedChart && batch" :workingSensor="workingSensors" :startDate="start_date" :endDate="end_date"/>
      <div class="container-inputs-buton" v-if="showProfileVisualization || userLevel == 'pecsmart'">
        <div class="container-dates">
          <div class="container-inputsDate">
            <span>De:</span>
            <input v-model="start_date"  type="datetime-local" style="margin-left: 16px; max-width: 200px; min-width:200px" :max="end_date" :min="minValidation">
          </div>
          <div class="container-inputsDate">
            <span >Ate:</span>
            <input v-model="end_date" type="datetime-local" style="margin-left: 12px; max-width: 200px; min-width:200px" :min="start_date">
          </div>
          <div class="container-buton">
            <button class="buttomDate" @click="newRangeData" >Atualizar periodo</button>
          </div>
          <div class="container-ração">
            <button 
              class="button-food" 
              data-toggle="modal"
              data-target="#foodModal">
                Ração
            </button>
          </div>
        </div> 
      </div>
      <div class="smartfeed-data-container" >
        <SmartFeed :seekNewRange="seekNewRange" :startDate="start_date" :endDate="end_date" :sensor="sensor" :key="sensor.pk" :useInformedWeight="useInformedWeight" :showProfileVisualization="showProfileVisualization" v-for="sensor in workingSensors" />
      </div>
    </div>
    <ModalFood :selectedFeed="selectedFeed" :allFood="allFoods"/>    
  </div>
</template>

<script>
import SmartFeed from "./SmartFeedV2.vue";
import SmartFeedConsolidatedData from "./SmartFeedConsolidatedData.vue";
import PageLoader from "../components/Loader.vue"
import ModalFood from "../components/ModalFood.vue"
import { URL } from "../store/index"
import { format, subDays, parseISO} from 'date-fns';

export default {
  name: "Navbar",
  components: {
    SmartFeed,
    SmartFeedConsolidatedData,
    PageLoader,
    ModalFood
  },
  data(){
    return{
      loading: true,
      start_date: format(subDays(new Date(), 7), 'yyyy-MM-dd HH:mm:ss'),
      end_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      seekNewRange: false,
    }
  },
  methods: {
    newRangeData() {
      this.seekNewRange = !this.seekNewRange
    },
    async fetchFoodByCompany () {
      try {
        const foodResponse = await fetch(`${URL}foods/?name=&company=${this.company}`, { method: "GET", headers: this.$store.getters.getHeader })
        const allFood = await foodResponse.json()

       this.$store.commit('setFoodByCompany', allFood)

      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    allFoods() {
      return this.$store.getters?.getFoodByCompany ?? []
    },
    showConsolidatedChart(){
      return !this.companySettings?.settings?.client_frontend?.hide_feed_consume_chart ?? true
    },
    showProfileVisualization(){
      return this.companySettings?.settings?.client_frontend?.show_feed_profile_visualization ?? false
    },
    workingSensors() {
      const data = [...this.sensorsFeedDataTarget].sort((a, b) => a.name.localeCompare(b.name));
      return data
    },
    barn() {
      return this.$store.getters.getBarnTarget
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
    listFeed() {
      return this.$store.getters.getSensorsListFeed
    },
    selectedFeed() {
      if (this.batch == null) {
        return this.listFeed.find(obj => obj.barn.pk == this.barn.pk)
      }
      return this.listFeed.find(obj => obj.batch.pk == this.batch.pk)
    },
    sensorsFeedDataTarget() {
      return this.selectedFeed?.data ?? []
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
    farm() {
      return this.$store.getters.getAllBarns.find((obj)=> {
        return obj.barn.find((barn)=>barn.pk == this.barn.pk)
      })
    },
    company() {
      return this.farm.farm.company
    },
    companySettings(){
      return this.$store.getters.getCompanySettings 
    },
    useInformedWeight(){
      return this.companySettings?.settings?.client_frontend?.use_informed_max_silo_weight ?? false
    },
    dateSevenDaysAgo() {
      return format(subDays(new Date(), 7), 'yyyy-MM-dd HH:mm:ss') 
    },
    startDate() {
      if (this.batch == undefined && this.showProfileVisualization) return format(subDays(new Date(), 7), 'yyyy-MM-dd HH:mm:ss')
      if (this.batch == undefined) return format(subDays(new Date(), 30), 'yyyy-MM-dd HH:mm:ss')

      if (this.batch.date_departure == null && this.showProfileVisualization) return format(subDays(new Date(), 7), 'yyyy-MM-dd HH:mm:ss')

      return format(parseISO(this.batch.date_accomodation), 'yyyy-MM-dd HH:mm:ss')
    },
    endDate() {
      const now = new Date();
      const formattedDate = format(now, 'yyyy-MM-dd HH:mm:ss');
       if (this.batch == undefined) return formattedDate
       if (this.batch.date_departure == null) return formattedDate
       return format(parseISO(this.batch.date_departure), 'yyyy-MM-dd HH:mm:ss')
    },
    minValidation() {
      if (this.batch == undefined) return ""
      if (this.batch.date_departure ==  null && this.showConsolidatedChart) return format(parseISO(this.batch.date_accomodation), 'yyyy-MM-dd HH:mm:ss')
      return this.batch.date_departure !== null ? format(parseISO(this.batch.date_accomodation), 'yyyy-MM-dd HH:mm:ss') : ""
    }
  },
  async created(){
    this.start_date = this.startDate
    this.end_date = this.endDate
    this.fetchFoodByCompany()
    if (this.userLevel == 'pecsmart') {
      await this.$store.dispatch('fetchCompanySettings', this.company)
      this.loading = false
      this.start_date = this.startDate
      this.end_date = this.endDate
    }
    this.loading = false
  }
};
</script>

<style scoped>
.maxcontainer{
  width: 100%;
  padding: 0px;
}

.container-ração{
  min-width: 200px;
  display: flex;
  justify-content: center;
}

.smartfeed-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around
  }

.container-inputsDate{
  display: flex;
  margin:4px;
  align-items: center;
  justify-content: center;
}

.container-inputs-buton{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 48px;
}

.button-food{
  background-color: #1b4238;
  color: #fff;
  padding: 8px;
  padding-inline: 12px;
  border-radius: 8px;
  border: none;
}

.button-food:hover{
  background-color: #1e7e34;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.container-dates{
  display: flex;
  align-items: center;
}

.container-buton{
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttomDate{
  margin:4px;
  padding: 8px;
  border-radius: 8px;
  background-color: #78b843 ;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  transition: background-color 0.2s ease-out;
}

.buttomDate:hover{
  background-color: #1e7e34;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

@media screen and (max-width: 1090px) {
  .smartfeed-data-container {
    flex-direction: column;
  }

  .container-dates{
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .smartfeed-data-container {
    flex-direction: column;
  }
}
</style>
