<template>
    <div>
        <a class="card-button" @click="changeBarnTarget(barn, batch, sensors, '/batch-details/smart-feed')">
          <AnimadePlaceHolder v-if="showFeed" height="40px" width="100%"  borderRadius="4px"/>
            <div class="feed-data-wrapper" v-else>
              <Tooltip
                v-for="feed in dataFeed"
                :key="feed.sensor"
                :p1="`${feed.message != undefined ? 'SEM DADOS' : feed.name}`"
                :p2="`${feed.message != undefined ? 'SEM DADOS' : feed.last_hour_level} %`"
                :p3="`${formatDate(feed.datetime)}`"
                :p4="`${formatDate(feed.datetime_last_ping)}`"
                title1="Nome: "
                title2="Nivel: "
                title3="Medido: "
                title4="Online: "
                :color="smartFeedColor(feed)"
                :class="size"
              >
                <div
                  class="progress-bar progress"
                  style="width: 100%; padding: 0; margin: 0 2px; border-radius: 4px"
                  :class="dataFeed.length > 4 ? `${smartFeedColor(feed)}` : `${smartFeedColor(feed)}`"
                >
                  <div class="container-information-td">
                    <p :class="feed.message != undefined ? 'fonteSenlote' : 'product-information'">
                      <span v-if="feed.message == undefined" :id="smartFeedColor(feed, true)">{{`${feed.name}:`}}</span> {{' '}} {{ feed.message != undefined ? 'SEM DADOS' : ` ${feed.last_hour_level} %` }}
                    </p>
                  </div>
                </div>
              </Tooltip> 
              <AnimadePlaceHolder v-if="sensors.length != dataFeed.length" height="40px" :width="dataFeed.length == 1 && this.loadingPLaceHolder ? '50%' : '100%'"  borderRadius="4px"/>
            </div> 
        </a>
    </div>
</template>

<script>
import  Tooltip  from '../components/Tooltip.vue';
import { URL } from "../store/index"
import AnimadePlaceHolder from './AnimadePlaceHolder.vue'
import { format, parseISO } from 'date-fns'

export default {
  name: "SensorsFeed",
  components: {
    AnimadePlaceHolder,
    Tooltip,
  },
  data() {
    return {
      dataFeedFromFetch:[],
      loading: true
    }
  },
  props: ["sensors", "indexBarn", 'barn', 'batch'],
  methods: {
    formatDate (date) {
      if (date) {
        return format(parseISO(date), 'dd-MM-yyyy hh:mm')
      } else {
        return 'SEM DADOS'
      }
    },
    smartFeedColor(feed, colorText) {
      if (colorText) {
        if (feed.message !== undefined) return 'feed-disabled-text'
        if (this.batch[0]?.date_departure != null) return 'feed-disabled-text'
        if (feed.last_hour_level > 30) {
          return 'peso-feed-bom-text'
        }
        if (feed.last_hour_level > 15) {
          return 'peso-feed-medio-text'
        }
        if (feed.message) {
          return 'feed-disabled-text'
        }
        return 'peso-feed-ruim-text'
      }
      if ( this.batch?.message) {
        if (feed.last_hour_level > 30) {
          return 'peso-feed-bom'
        }
        if (feed.last_hour_level > 15) {
          return 'peso-feed-medio'
        }
        if (feed.message) {
          return 'feed-disabled'
        }
        return 'peso-feed-ruim'
      } 
      if (feed.message !== undefined) return 'feed-disabled'
      if (this.batch[0]?.date_departure != null) return 'feed-disabled'
      if (feed.last_hour_level > 30) {
        return 'peso-feed-bom'
      }
      if (feed.last_hour_level > 15) {
        return 'peso-feed-medio'
      }
      return 'peso-feed-ruim'
    },
    async getDataFeed() {
        for (const element of this.sensors) {
          try {
            this.$store.commit('incrementActiveRequests');
            let url_to_fetch = `${URL}sensors/report/data/home/smartfeed/smartfeed_pk=${element.pk}`;
            const response = await fetch(url_to_fetch, {
                headers: this.$store.getters.getHeader
            });
            const json = await response.json();
            if (json[0] == undefined) {
              this.dataFeedFromFetch.push(json);
            } else {
              this.dataFeedFromFetch.push(json[0]);
            }

            this.loading = false
            const allDataFeed = {
              barn: this.barn,
              batch: this.batch[0],
              sensor: this.sensors,
              data:this.dataFeedFromFetch
            }
            this.$store.commit('setSensorsListFeed', allDataFeed)
          } finally {
            this.$store.commit('decrementActiveRequests');
        }
      }
    },
    changeBarnTarget(barn, batch, sensors, route) {
      if (this.dataFeed.length > 0) {
        this.$store.commit("setBarnTarget", barn)
        this.$store.commit("setBatchTarget", batch[0] == undefined ? null : batch[0])
        this.$router.push(route)
      }
    },
    orderByName(array) {
      return array.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
  },
  computed: {
    loadingPLaceHolder() {
      return this.sensors.length != this.dataFeed.length
    },
    size() {
      if (this.dataFeed.length == 1 && this.loadingPLaceHolder) return 'feed-data'
      if (this.dataFeed.length == 1) return 'feed-data-one'
      if (this.dataFeed.length <= 4) return 'feed-data'
      return 'feed-data-big-amout'
    },
    getDataFeedSave(){
      return this.$store.getters.getSensorsListFeed
    },
    selectedFeed() {
      return this.$store.getters.getSensorsListFeed.find(obj => obj.batch.pk == this.batch[0].pk)
    },
    dataFeed() {
      if (this.isFirstBatch == 'Sem lote cadastrado neste barracão.') {
        return this.orderByName(this.dataFeedFromFetch)
      }
      return this.orderByName(this.selectedFeed?.data ?? [])
    },
    isFirstBatch() {
      return this.batch?.message ?? ''
    },
    showFeed() {
      if (this.isFirstBatch == 'Sem lote cadastrado neste barracão.') {
        return this.dataFeedFromFetch.length == 0
      }
      return this.getDataFeedSave.length == 0
    }
  },
  async created() {
    if (this.dataFeed.length == 0) {
      await this.getDataFeed()
    }
  }
};

</script>

<style scoped>
.cursor{
  cursor: not-allowed;
}

.progress {
  border-radius: 0;
  height: 40px;
  background-color: #f4f4f4;
  font-weight: bold;
  font-size: 14px;
}

.progress-bar {
  text-align: left;
  padding-left: 10px;
}


a {text-decoration: none;}
.peso-atual-acima .progress-bar {background: #415430;}
a .peso-atual-acima .progress-bar:hover {background: #273619;}
.peso-atual-bom .progress-bar {background: #a1cd7b;}
a .peso-atual-bom .progress-bar:hover {background: #5e7d42;}
.peso-atual-medio .progress-bar {background: #e2bf65;}
a .peso-atual-medio .progress-bar:hover {background: #c59e3a;}
.peso-atual-ruim .progress-bar {background: #a94949;}
a .peso-atual-ruim .progress-bar:hover {background: #952323;}
.no-sensor .progress-bar {background:  #f2f2f2; color: grey;}
a .no-sensor .progress-bar:hover {background: #cfcfcf;}
.disable .progress-bar {background:  #adadad;}
a .disable .progress-bar:hover {background: grey;}

.feed-data-wrapper {
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.feed-data {
  flex: 1 1 calc(50% - 5px); 
  max-width: calc(50% - 5px); 
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 
}

.feed-data-big-amout {
  flex: 1 1 calc(50% - 5px); 
  max-width: calc(50% - 5px); 
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 
}

.feed-data-one {
  flex: 1 1 calc(100% - 10px); 
  max-width: calc(100% - 10px); 
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 
}

.fonteSenlote{
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 12px
}

.container-information-td{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.imgIcon{
  width: 20px;
  margin: 4px;
  margin-right: 8px;

}

.feed-data {
  width: -moz-available;
  width: -webkit-fill-available;
}

.peso-feed-acima {background: #415430;}
.peso-feed-acima:hover {background: #273619;}
.peso-feed-bom {background: #a1cd7b;}
.peso-feed-bom:hover {background: #5e7d42;}
.peso-feed-medio {background: #e2bf65;}
.peso-feed-medio:hover {background: #c59e3a;}
.peso-feed-ruim {background: #c25757;}
.peso-feed-ruim:hover {background: #952323;}
.feed-disabled {background:  #adadad;}
.feed-disabled:hover {background: grey;}

.product-information {
  margin: 0px;
  padding: 0px;
  font-size: 12px
}

.product-information span{
  margin: 0px;
  margin-right: 4px;
  padding: 0px;
  font-size: 12px;
  font-weight: 700;
}


#peso-feed-bom-text{
  color: #003d03;
}

#peso-feed-medio-text {
  color:#3d3900;
}

#feed-disabled-text {
  color: white
}

#peso-feed-ruim-text{
  color:#3d0000; 
}

.card-button {
  cursor: pointer;
}

@media screen and (min-width: 991px) {
  .imgIcon{
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .product-information {
    font-size: 10px
  }
  .product-information span{
    font-size: 10px
  }
}
</style>
